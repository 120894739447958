<template>
   <div class="container">
    <!-- 页头 -->
    <content-title :nav="nav"></content-title>
    <!-- 表头 -->
    <!-- <div class="topup-title"></div> -->
     <h4 class="sec-title" >优学卡定金列表</h4>
    <!-- 查询
    <div class="search">
      <el-form :inline="true" :model="form">
        <el-form-item label="订单编号">
          <el-input v-model="form.order"></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="机构名称">
          <el-input v-model="form.institutionsName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="aaa">查询</el-button>
        </el-form-item>
      </el-form>
    </div> -->
    <!-- 列表 -->
    <div>
      <el-table :data="tableData" border>
        <el-table-column prop="flowing_no" label="订单编号"> </el-table-column>
        <el-table-column
          prop="map.userinfo.nick_name"
          label="用户名称"
        ></el-table-column>

        <el-table-column prop="map.userinfo.mobile" label="手机号">
        </el-table-column>
        <el-table-column prop="rcharge_abstract" label="订单类别">
        </el-table-column>
        <el-table-column prop="finished_time" label="支付时间">
        </el-table-column>
        <el-table-column prop="title" label="科目选择">
          <template slot-scope="scope">
            <span
              v-for="(item, index) in scope.row.map.selectMasterSetPriceEntitys"
              :key="index"
              >{{ item.title }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
      ></el-pagination> -->
      <!-- 分页 -->
      <paging-fy  @currentPageChange="handleCurrentChange" 
      :currentPage="currentPage" :total="total"></paging-fy>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: {
        firstNav: '活动管理中心',
        secondNav: '优学卡订金列表',
      },
      //分页
      pageSize: 10,
      currentPage: 1,
      total: 0,
      //查询
      form: { order: "", mobile: "", institutionsName: "" },
      //列表
      tableData: [],
    };
  },
  methods: {
    GetList(data) {
      let aaa;
      if (data) {
        aaa = {
          rcharge_type: "deposit",
          pageSize: 1,
          currentPage: this.currentPage,
          status: "2",
          ...data,
        };
      } else {
        aaa = {
          rcharge_type: "deposit",
          pageSize: 10,
          currentPage: this.currentPage,
          status: "2",
        };
      }
      let url = "/user/rechargerecord/queryByMessage";

      this.$axios.get(url, { params: aaa })
        .then((res) => {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    aaa() {
      var data = {
        flowing_no: this.form.order,
        login_name: this.form.mobile,
        mechanism_name: this.form.institutionsName,
      };
      this.GetList(data);
    },

    handleCurrentChange(val) {
        
      this.currentPage = val;
      this.GetList();
    },
  },
  mounted() {
    this.GetList();
  },
};
</script>
<style scoped lang="less">
//  @import '@/assets/css/Tutop.css';

.topup-hread {
  position: relative;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.topup-hread> .el-breadcrumb__inner {
  font-weight: bold;
  font-size: 20px;
}

.topup-title {
  position: relative;
  width: 100%;
  height: 60px;
  text-align: center;
  font-size: 24px;
  color: #000;
  font-weight: bold;
}

.search /deep/ .el-input__inner {
  height: 28px;
}

.search /deep/ .el-button--primary {
  height: 30px;
  background-color: #47cecf;
  border: none;
  line-height: 30px;
  padding: 0 10px;
}
</style>